@tailwind base;
@tailwind components;
@tailwind utilities;

.animated-border {
    background-size: 200%;
    animation: gradient-animation 6s linear infinite;
}
  
@keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
}

/*Without this, the chart component will prevent scrolling on mobile if the user starts scrolling from within the container*/
.VictoryContainer {
  user-select: auto !important;
  pointer-events: auto !important;
  touch-action: auto !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--cb-gray-400) var(--cb-gray-600);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--cb-gray-400);
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  background-color: var(--cb-gray-600);
}

/*Colors should be declared here and then imported into tailwind config from here so they can be used in className AND svgs*/
:root {
    --cb-green: #01DA63;
    --cb-gray-400: #343735;
    --cb-gray-500: #2C3631;
    --cb-gray-600: #1E211F;
    --cb-gray-700: #151715;
    --gauge-red: #FF5858;
    --gauge-green: #28E92F;
    --gauge-yellow: #F8A832;

    --burger-btn-offset: 4rem;
}
  
